/* ****************
 * GENERATED CODE *
 **************** */

import { BasicEvent } from "@h4x/common";
import { HewSyncList } from "../../system/HewSyncList";
import { HewSyncType } from "../../system/HewSyncType";
import { HewSync, type Props, type PartialProps } from "../../system/HewSync";
import { UserGroupID, UserOrganizationID } from "../../system/IDs";


@HewSync.Type({
  "name": "UserGroup",
  "scope": "User",
  "table": "user",
  "type": "User:Group"
})
export class UserGroup extends HewSyncType {
	public static readonly Type = UserGroup as typeof HewSyncType;
	public static override readonly type = "UserGroup";
	public override readonly type = UserGroup.type;

	@HewSync.Field({ })
	public readonly group: UserGroupID;

	@HewSync.Field({ })
	public readonly organization: UserOrganizationID;

	@HewSync.Field({ })
	public readonly description: string;

	@HewSync.Field({ })
	public readonly name: string;

	@HewSync.Field({ })
	public readonly createdAt: HewSync.Timestamp;

	@HewSync.Field({ })
	public readonly updatedAt: HewSync.Timestamp;


	/********************************************/

	public static get(ids: Pick<UserGroup, UserGroup.IDs>) {
		this.initSubscriptions();

		let key = this.getKey(ids);
		let item = this.cache.get(key);

		if (item === undefined) {
			item = new UserGroup({
				...UserGroup.defaults(),
				group: ids.group,
				organization: ids.organization,
			});
			this.cache.set(key, item);
			item.fetch(item.ids);
		}

		return item;
	}

	public static rawGet(ids: Pick<UserGroup, UserGroup.IDs>) {
		return HewSync.get<UserGroup>(UserGroup.Type, ids);
	}

	public static list(/*...*/) {
		// TODO
	}

	public static listBy(params?: Partial<Pick<UserGroup, UserGroup.IDs>>): HewSyncList<UserGroup> {
		let list = new (HewSyncList as any)(UserGroup.Type, undefined, params);
		return list;
	}

	public static rawList(inputs: Partial<Pick<UserGroup, UserGroup.IDs>> & { limit?: number; nextToken?: string; }) {
		return HewSync.list<UserGroup>(UserGroup.Type, inputs);
	}

	public static batchGet(ids: Pick<UserGroup, UserGroup.IDs>[]) {
		return HewSync.batchGet<UserGroup>(UserGroup.Type, ids);
	}

	public static create(values: UserGroup.Create) {
		return HewSync.create<UserGroup>(UserGroup.Type, "create", values);
	}

	public static update(ids: Pick<UserGroup, UserGroup.IDs>, values: Partial<Pick<UserGroup, UserGroup.Modifiable>>, target?: UserGroup) {
		return HewSync.request<UserGroup>(UserGroup.Type, "update", {...values, ...ids}, target);
	}

	public static remove(ids: Pick<UserGroup, UserGroup.IDs>, target?: UserGroup) {
		return HewSync.request<UserGroup>(UserGroup.Type, "remove", ids, target);
	}

	/********************************************/

	public update(values: Partial<Pick<UserGroup, UserGroup.Modifiable>>) {
		return UserGroup.update(this.ids, values, this);
	}

	public remove() {
		return UserGroup.remove(this.ids, this);
	}

	/********************************************/

	private static cache = new Map<string, UserGroup>();

	public get ids() { return { organization: this.organization, group: this.group }; }

	public static getKey(ids: Pick<UserGroup, UserGroup.IDs>) { return ids.organization.toString() + "/" + ids.group.toString(); }

	public get key() { return this.organization.toString() + "/" + this.group.toString(); }

	protected constructor(data: any) {
		super(Symbol.for("HewSyncType::Internal::Create") as unknown as void);

		(this as any).group = data.group;
		(this as any).organization = data.organization;

		this.apply({ ...UserGroup.defaults(), ...data });
	}

	protected override apply(data: Partial<Pick<UserGroup, UserGroup.Fields>>) {
		(this as any).description = data.description;
		(this as any).name = data.name;
		(this as any).createdAt = data.createdAt;
		(this as any).updatedAt = data.updatedAt;
		this.onUpdate.execute(this);
	}

	protected static defaults() {
		let values = {} as UserGroup;
		(values as any).description = "";
		(values as any).name = "";
		(values as any).createdAt = new HewSync.Timestamp("1970-01-01T00:00:00Z");
		(values as any).updatedAt = new HewSync.Timestamp("1970-01-01T00:00:00Z");
		return values;
	}

	protected static parse(data: any): PartialProps<UserGroup, UserGroup.IDs | UserGroup.Fields> {
		let output: PartialProps<UserGroup, UserGroup.IDs | UserGroup.Fields> = {};
		if (data.group !== undefined) {
			output.group = new UserGroupID(data.group);
		}
		if (data.organization !== undefined) {
			output.organization = new UserOrganizationID(data.organization);
		}
		if (data.description !== undefined) {
			output.description = data.description;
		}
		if (data.name !== undefined) {
			output.name = data.name;
		}
		if (data.createdAt !== undefined) {
			output.createdAt = new HewSync.Timestamp(data.createdAt);
		}
		if (data.updatedAt !== undefined) {
			output.updatedAt = new HewSync.Timestamp(data.updatedAt);
		}
		return output;
	}

	protected toJSON(): any {
		return {
			group: this.group,
			organization: this.organization,
			description: this.description,
			name: this.name,
			createdAt: this.createdAt,
			updatedAt: this.updatedAt,
		};
	}

	private static onSubscriptionEvent = new BasicEvent<(data: any) => void>();

	private static subscriptionsInitialized = false;
	static initSubscriptions() {
		if (this.subscriptionsInitialized === false) {
			void HewSync.initSubscriptions(this.Type, this.onSubscriptionEvent);
			this.subscriptionsInitialized = true;
		}
	}

	public static readonly fieldIDs = ["organization", "group"] as const;
	public readonly fieldIDs = UserGroup.fieldIDs;
	public static readonly fields = ["description", "name", "createdAt", "updatedAt"] as const;
	public readonly fields = UserGroup.fields;
}

export namespace UserGroup {
	export type IDs = "organization" | "group";
	export type Fields = "description" | "name" | "createdAt" | "updatedAt";
	export type Modifiable = "description" | "name";
	export type Create = {
		organization: UserOrganizationID;
		description?: string;
		name: string;
	}

}
