/* ****************
 * GENERATED CODE *
 **************** */

import { BasicEvent } from "@h4x/common";
import { HewSyncList } from "../../system/HewSyncList";
import { HewSyncType } from "../../system/HewSyncType";
import { HewSync, type Props, type PartialProps } from "../../system/HewSync";
import { UserOrganizationID, UserRoleID } from "../../system/IDs";


@HewSync.Type({
  "name": "UserRole",
  "scope": "User",
  "table": "user",
  "type": "User:Role"
})
export class UserRole extends HewSyncType {
	public static readonly Type = UserRole as typeof HewSyncType;
	public static override readonly type = "UserRole";
	public override readonly type = UserRole.type;

	@HewSync.Field({ })
	public readonly organization: UserOrganizationID;

	@HewSync.Field({ })
	public readonly role: UserRoleID;

	@HewSync.Field({ })
	public readonly description: string;

	@HewSync.Field({ })
	public readonly name: string;

	@HewSync.Field({ })
	public readonly permissions: any;

	@HewSync.Field({ })
	public readonly createdAt: HewSync.Timestamp;

	@HewSync.Field({ })
	public readonly updatedAt: HewSync.Timestamp;


	/********************************************/

	public static get(ids: Pick<UserRole, UserRole.IDs>) {
		this.initSubscriptions();

		let key = this.getKey(ids);
		let item = this.cache.get(key);

		if (item === undefined) {
			item = new UserRole({
				...UserRole.defaults(),
				organization: ids.organization,
				role: ids.role,
			});
			this.cache.set(key, item);
			item.fetch(item.ids);
		}

		return item;
	}

	public static rawGet(ids: Pick<UserRole, UserRole.IDs>) {
		return HewSync.get<UserRole>(UserRole.Type, ids);
	}

	public static list(/*...*/) {
		// TODO
	}

	public static listBy(params?: Partial<Pick<UserRole, UserRole.IDs>>): HewSyncList<UserRole> {
		let list = new (HewSyncList as any)(UserRole.Type, undefined, params);
		return list;
	}

	public static rawList(inputs: Partial<Pick<UserRole, UserRole.IDs>> & { limit?: number; nextToken?: string; }) {
		return HewSync.list<UserRole>(UserRole.Type, inputs);
	}

	public static batchGet(ids: Pick<UserRole, UserRole.IDs>[]) {
		return HewSync.batchGet<UserRole>(UserRole.Type, ids);
	}

	public static create(values: UserRole.Create) {
		return HewSync.create<UserRole>(UserRole.Type, "create", values);
	}

	public static update(ids: Pick<UserRole, UserRole.IDs>, values: Partial<Pick<UserRole, UserRole.Modifiable>>, target?: UserRole) {
		return HewSync.request<UserRole>(UserRole.Type, "update", {...values, ...ids}, target);
	}

	public static remove(ids: Pick<UserRole, UserRole.IDs>, target?: UserRole) {
		return HewSync.request<UserRole>(UserRole.Type, "remove", ids, target);
	}

	/********************************************/

	public update(values: Partial<Pick<UserRole, UserRole.Modifiable>>) {
		return UserRole.update(this.ids, values, this);
	}

	public remove() {
		return UserRole.remove(this.ids, this);
	}

	/********************************************/

	private static cache = new Map<string, UserRole>();

	public get ids() { return { organization: this.organization, role: this.role }; }

	public static getKey(ids: Pick<UserRole, UserRole.IDs>) { return ids.organization.toString() + "/" + ids.role.toString(); }

	public get key() { return this.organization.toString() + "/" + this.role.toString(); }

	protected constructor(data: any) {
		super(Symbol.for("HewSyncType::Internal::Create") as unknown as void);

		(this as any).organization = data.organization;
		(this as any).role = data.role;

		this.apply({ ...UserRole.defaults(), ...data });
	}

	protected override apply(data: Partial<Pick<UserRole, UserRole.Fields>>) {
		(this as any).description = data.description;
		(this as any).name = data.name;
		(this as any).permissions = data.permissions;
		(this as any).createdAt = data.createdAt;
		(this as any).updatedAt = data.updatedAt;
		this.onUpdate.execute(this);
	}

	protected static defaults() {
		let values = {} as UserRole;
		(values as any).description = "";
		(values as any).name = "";
		(values as any).permissions = [];
		(values as any).createdAt = new HewSync.Timestamp("1970-01-01T00:00:00Z");
		(values as any).updatedAt = new HewSync.Timestamp("1970-01-01T00:00:00Z");
		return values;
	}

	protected static parse(data: any): PartialProps<UserRole, UserRole.IDs | UserRole.Fields> {
		let output: PartialProps<UserRole, UserRole.IDs | UserRole.Fields> = {};
		if (data.organization !== undefined) {
			output.organization = new UserOrganizationID(data.organization);
		}
		if (data.role !== undefined) {
			output.role = new UserRoleID(data.role);
		}
		if (data.description !== undefined) {
			output.description = data.description;
		}
		if (data.name !== undefined) {
			output.name = data.name;
		}
		if (data.permissions !== undefined) {
			output.permissions = data.permissions;
		}
		if (data.createdAt !== undefined) {
			output.createdAt = new HewSync.Timestamp(data.createdAt);
		}
		if (data.updatedAt !== undefined) {
			output.updatedAt = new HewSync.Timestamp(data.updatedAt);
		}
		return output;
	}

	protected toJSON(): any {
		return {
			organization: this.organization,
			role: this.role,
			description: this.description,
			name: this.name,
			permissions: this.permissions,
			createdAt: this.createdAt,
			updatedAt: this.updatedAt,
		};
	}

	private static onSubscriptionEvent = new BasicEvent<(data: any) => void>();

	private static subscriptionsInitialized = false;
	static initSubscriptions() {
		if (this.subscriptionsInitialized === false) {
			void HewSync.initSubscriptions(this.Type, this.onSubscriptionEvent);
			this.subscriptionsInitialized = true;
		}
	}

	public static readonly fieldIDs = ["organization", "role"] as const;
	public readonly fieldIDs = UserRole.fieldIDs;
	public static readonly fields = ["description", "name", "permissions", "createdAt", "updatedAt"] as const;
	public readonly fields = UserRole.fields;
}

export namespace UserRole {
	export type IDs = "organization" | "role";
	export type Fields = "description" | "name" | "permissions" | "createdAt" | "updatedAt";
	export type Modifiable = "description" | "name" | "permissions";
	export type Create = {
		organization: UserOrganizationID;
		description?: string;
		name: string;
		permissions?: any;
	}

}
